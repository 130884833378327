import React, { useState, useEffect } from "react";
import SeoTags from "../components/seo";
import NewsItem from "../components/news/item";
import Header from "../components/header";
import PromoApp from "../components/promoApp";
import MostPopular from "../components/mostPopular";
import MostLikes from "../components/mostLikes";
import MostComments from "../components/mostComments";

function News(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { lang, locale } = props;
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(11);
  const [loaded, setLoaded] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        offset: offset,
        count: count,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_BACKEND_ROOT + "get_news.php", requestOptions)
      .then((response) => response.json())
      .then((news) => {
        news.length === 11 ? setLoaded(false) : setLoaded(true);
        setData(news);
        setOffset(11);
        setCount(10);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const loadMore = () => {
    if (!loaded) {
      setLoadingMore(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          city_id: process.env.REACT_APP_CITY_ID,
          offset: offset,
          count: count,
          origin: "web",
        }),
      };
      fetch(process.env.REACT_APP_BACKEND_ROOT + "get_news.php", requestOptions)
        .then((response) => response.json())
        .then((news) => {
          let newData = [...data, ...news];
          setData(newData);
          if (news.length === 10) {
            setOffset(offset + 10);
          } else {
            setLoaded(true);
          }
          setLoadingMore(false);
        });
    }
  };

  return (
    <>
      <SeoTags
        title="Vesti"
        description="Pogledajte najnovije vesti iz Aranđelovca."
        path="vesti/"
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo="/"
              firstLine={process.env.REACT_APP_CITY.translate()}
              secondLine={"Vesti".translate()}
              loading={loading}
              icon={<i className="bi bi-newspaper me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-lg-8">
              <div className="row g-0 px-2 pt-1 pb-3">
                {loading && (
                  <>
                    <NewsItem i={0} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                    <NewsItem i={1} loading={loading} />
                  </>
                )}
                {!loading &&
                  data.map((news, i) => (
                    <NewsItem
                      key={news.news_id}
                      lang={lang}
                      data={news}
                      locale={locale}
                      i={i}
                      loading={loading}
                    />
                  ))}
                {!loaded && !loadingMore && (
                  <div className="mt-3 mb-1 text-center">
                    <button onClick={loadMore} className="load-more-button">
                      {"Prikaži više".translate()}
                    </button>
                  </div>
                )}
                {loadingMore && (
                  <div className="loading-more mt-3 mb-1">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-column mt-3 pb-3">
                <MostPopular
                  lang={lang}
                  showSubheader={true}
                  locale={locale}
                  type="news"
                  category="0"
                />
                <MostLikes
                  lang={lang}
                  showSubheader={true}
                  locale={locale}
                  type="news"
                  category="0"
                />
                <MostComments
                  lang={lang}
                  showSubheader={true}
                  locale={locale}
                  type="news"
                  category="0"
                />
                <PromoApp sidebar={true} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default News;
