import React from "react";
import { Link } from "react-router-dom";
function SiteFooter() {
  return (
    <div className="container-fluid bg-dark">
      <footer className="container-md py-3 my-0">
        <div className="row">
          <div className="col-12 justify-content-center d-flex mb-2">
            <img
              src={process.env.REACT_APP_MAIN_LOGO}
              className="d-inline-block align-top"
              alt={process.env.REACT_APP_NAME.translate() + " logo"}
              height="60px"
            />
          </div>
          <div className="col-12">
            <ul className="nav justify-content-center mb-2 footer-links">
              <li className="nav-item">
                <Link to="/kontakt" className="nav-link px-2">
                  {"Kontakt".translate()}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/politika-o-kolacicima" className="nav-link px-2">
                  {"Politika o kolačićima".translate()}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/politika-privatnosti" className="nav-link px-2">
                  {"Politika privatnosti".translate()}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row footer-down">
          <div className="col-6 footer-copy">
            {"© 2024 " + "Izdavač".translate()}
          </div>
          <div className="col-6 footer-icons">
            {process.env.REACT_APP_IPHONE_LINK !== "#" && (
              <div>
                <a
                  href={process.env.REACT_APP_IPHONE_LINK}
                  target="_blank"
                  title={"Preuzmi aplikaciju na App Store".translate()}
                >
                  <i className="bi bi-apple"></i>
                </a>
              </div>
            )}
            {process.env.REACT_APP_ANDROID_LINK !== "#" && (
              <div>
                <a
                  href={process.env.REACT_APP_ANDROID_LINK}
                  target="_blank"
                  title={"Preuzmi aplikaciju na Google Play".translate()}
                >
                  <i className="bi bi-google-play"></i>
                </a>
              </div>
            )}
            {process.env.REACT_APP_FACEBOOK_LINK !== "#" && (
              <div>
                <a
                  href={process.env.REACT_APP_FACEBOOK_LINK}
                  target="_blank"
                  title={"Poseti našu Facebook stranicu".translate()}
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </div>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default SiteFooter;
