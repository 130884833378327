import React from "react";

function PromoApp(props) {
  const { sidebar } = props;
  return (
    <div
      className={
        sidebar
          ? "container-fluid search-promo-bg-sidebar p-3"
          : "container-fluid search-promo-bg p-3"
      }
    >
      <div className="container-md">
        <div
          className={
            sidebar ? "row search-promo-wrap-sidebar" : "row search-promo-wrap"
          }
        >
          <div className="col-5 search-promo-left">
            <img
              src={process.env.REACT_APP_STATIC_RESOURCES + "phones.png"}
              // height="50"
              alt={"iPhone / Android aplikacija".translate()}
            />
          </div>
          <div
            className={
              sidebar
                ? "col search-promo-right-sidebar"
                : "col search-promo-right"
            }
          >
            <h2>{"iPhone / Android aplikacija".translate()}</h2>
            <p>
              {"Preuzmite".translate() +
                " " +
                process.env.REACT_APP_NAME.translate() +
                " " +
                "aplikaciju za vaš iPhone ili Android mobilni uređaj.".translate()}
            </p>
            <div
              className={
                sidebar ? "search-promo-apps-sidebar" : "search-promo-apps"
              }
            >
              {process.env.REACT_APP_IPHONE_LINK === "" && (
                <div>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_RESOURCES + "app-store.svg"
                    }
                    height="50"
                    alt={"Uskoro".translate()}
                    title={"Uskoro".translate()}
                  />
                </div>
              )}
              {process.env.REACT_APP_IPHONE_LINK !== "" && (
                <a href={process.env.REACT_APP_IPHONE_LINK}>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_RESOURCES + "app-store.svg"
                    }
                    height="50"
                    alt={"Preuzmi aplikaciju na App Store".translate()}
                    title={"Preuzmi aplikaciju na App Store".translate()}
                  />
                </a>
              )}
              {process.env.REACT_APP_ANDROID_LINK === "" && (
                <div>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_RESOURCES + "play-store.svg"
                    }
                    height="50"
                    alt={"Uskoro".translate()}
                    title={"Uskoro".translate()}
                  />
                </div>
              )}
              {process.env.REACT_APP_ANDROID_LINK !== "" && (
                <a href={process.env.REACT_APP_ANDROID_LINK}>
                  <img
                    src={
                      process.env.REACT_APP_STATIC_RESOURCES + "play-store.svg"
                    }
                    height="50"
                    alt={"Preuzmi aplikaciju na Google Play".translate()}
                    title={"Preuzmi aplikaciju na Google Play".translate()}
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoApp;
