import React from "react";
import { useState, useEffect } from "react";
import PromoApp from "../components/promoApp";
import Header from "../components/header";
import Group from "../components/home/group";
import MostPopular from "../components/locations/mostPopular";
import MostLikesComments from "../components/locations/mostLikesComments";
import SidebarLatest from "../components/locations/sidebarLatest";
import SeoTags from "../components/seo";

function Locations(props) {
  const { lang, locale } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupList, setGroupList] = useState("");

  useEffect(() => {
    const requestOptionsSearch = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        origin: "web",
      }),
    };
    fetch(
      process.env.REACT_APP_BACKEND_ROOT + "get_groups.php",
      requestOptionsSearch
    )
      .then((response) => response.json())
      .then((objects) => {
        let objList = "";
        let totalObjects = objects.length;
        objects.map((group, i) => {
          objList += group.name_sr;
          i + 1 < totalObjects ? (objList += ", ") : (objList += ".");
        });
        setGroupList(objList);
        // let newData = [];
        // objects.map((group, i) => {
        //   group.subgroup_visible = true;
        //   newData[i] = group;
        // });
        // setData(newData);
        setData(objects);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  function handleToggleSubmenu(index) {
    let newData = [];
    data.map((group, i) => {
      let currentState = group.subgroup_visible;
      i === index
        ? (group.subgroup_visible = !currentState)
        : (group.subgroup_visible = false);
      newData[i] = group;
    });
    setData(newData);
  }
  return (
    <>
      <SeoTags
        title="Lokacije"
        description={
          "Pogledajte najatraktivnije lokacije u Aranđelovcu iz kategorija " +
          groupList
        }
        path="lokacije/"
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo="/"
              firstLine={process.env.REACT_APP_CITY.translate()}
              secondLine={"Lokacije".translate()}
              loading={loading}
              icon={<i className="bi bi-geo-alt me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-lg-8">
              <div className="row g-0 px-2 pt-1 pb-3">
                {loading && (
                  <>
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                    <Group loading={loading} />
                  </>
                )}
                {!loading &&
                  data.map((group, index) => (
                    <Group
                      key={group.group_id}
                      onToggleSubmenu={handleToggleSubmenu}
                      index={index}
                      group={group}
                      lang={lang}
                      loading={loading}
                    />
                  ))}
              </div>
              <div className="row g-2 px-2 pt-1 pb-3">
                <MostPopular lang={lang} showSubheader={true} category="0" />
                <MostLikesComments lang={lang} showSubheader={true} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-column mt-3 pb-3">
                <SidebarLatest lang={lang} showSubheader={true} />
                <PromoApp sidebar={true} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Locations;
